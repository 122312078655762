.text-green {
  color: #1c9d87 !important;
}

.text-green-light {
  color: #2ecf96 !important;
}

.text-red {
  color: #ff2c2c !important;
}

.text-light-grey {
  color: #a5b5af !important;
}

.text-light-grey-1 {
  color: #818d88 !important;
}

.text-light-grey-2 {
  color: #535865 !important;
}

.text-dark-1 {
  color: #142c1f !important;
}

.text-dark-2 {
  color: #44554c !important;
}

.text-dark-3 {
  color: #2c2c2c !important;
}

.font-rubik-medium,
.font-rubik-regular,
.font-rubik-light {
  font-family: "Rubik", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

.font-rubik-medium {
  // font-family: "RubikMedium" !important;
  font-weight: 500 !important;
}

.font-rubik-regular {
  // font-family: "RubikRegular" !important;
  font-weight: 400 !important;
}

.font-rubik-light {
  // font-family: "RubikLight" !important;
  font-weight: 300 !important;
}

.h1,
.h2,
.h3,
.p1,
.p2,
.p3,
.p5,
.p6 {
  margin: 0;
}

.h1 {
  font-size: 2.375rem;
}

.h2 {
  font-size: 1.375rem;
}

.h3 {
  font-size: 1.5rem;
}

.p1 {
  font-size: 1.125rem;
}

.p2 {
  font-size: 0.875rem;
}

.p3 {
  font-size: 1rem;
}

.p5 {
  font-size: 0.875rem !important;
}

.p6 {
  font-size: 0.75rem !important;
}

.text-size-22-32 {
  font-size: 1.375rem !important;
  line-height: 2rem !important;
}

.text-size-20-28 {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}
.text-size-20-24 {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}

.text-size-18-22 {
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
}

.text-size-18-24 {
  font-size: 1.125rem !important;
  line-height: 1.5rem !important;
}

.text-size-18-28 {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-size-16-19 {
  font-size: 1rem !important;
  line-height: 1.1875rem !important;
}

.text-size-16-20 {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
}

.text-size-16-24 {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-size-16-28 {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
}

.text-size-14-28 {
  font-size: 0.875rem !important;
  line-height: 1.75rem !important;
}

.text-size-14-17 {
  font-size: 0.875rem !important;
  line-height: 1.0625rem !important;
}

.text-size-14-18 {
  font-size: 0.875rem !important;
  line-height: 1.125rem !important;
}

.text-size-14-19 {
  font-size: 0.875rem !important;
  line-height: 1.1875rem !important;
}

.text-size-14-20 {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-size-28-38 {
  font-size: 1.75rem !important;
  line-height: 2.375rem !important;
}

.padding-body {
  padding: 1.75rem 2.1875rem !important;
}

.companies {
  display: flex;
  gap: 1rem;
  img {
    filter: grayscale(1);
  }
}

.steps-cards {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.4375rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .step-card {
    padding: 2.8125rem 1.25rem;
    background: #ffffff;
    box-shadow: 0px 2px 5px #0000001a;
    border-radius: 0.9375rem;

    h5,
    p {
      margin: 0;
    }

    h5 {
      font-size: 1.125rem;
      color: #142c1f;
      margin: 1.875rem 0 1.25rem;
    }

    p {
      color: #44554c;
      font-size: 0.875rem;
      opacity: 0.7;
      line-height: 1.3125rem;
    }
  }
}

.trainer-intro-content {
  display: flex;
  gap: 1rem;

  .profile-img {
    img {
      width: 15.625rem;
      border-radius: 1rem;
    }
  }
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  .testimonial {
    .testimonial-img {
      padding: 0.2125rem;
      border: 3px solid #8dcec3;
      border-radius: 50%;
      width: 4.5rem;
      height: 4.5rem;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .testimonial-content {
      .stars {
      }

      .p3 {
        margin: 0.225rem 0;
      }
    }
  }
}

.app-functions {
  display: grid;
  grid-template-columns: 15.875rem 1fr;
  gap: 1rem;

  .app-functions-image {
    img {
      width: 100%;
    }
  }

  .app-functions-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    .badge {
      background: #2ecf96;
      border-radius: 0.2875rem;
      color: white;
      font-size: 0.775rem;
      margin-left: 0.625rem;
      padding: 0.1875rem 0.375rem;
    }
  }
}

.benefits-tabs {
  display: flex;
  width: fit-content;
  background: #fdfdfd;
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 1.5625rem;
  padding: 0.4375rem 0.625rem;
  margin-inline: auto;

  .tab {
    padding: 0.5rem 1rem;
    color: #142c1f;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    border: 2px solid transparent;

    &.active {
      color: #2ecf96;
      background: #ffffff;
      box-shadow: 0px 2px 4px #02332a14;
      border-color: #44554c40;
      border-radius: 1.625rem;
    }
  }
}

.dashboard-functions-tab {
  .main-img {
    width: 100%;
    background: #fdfdfd;
    box-shadow: 0px 2px 5px #0000001a;
    border: 2px solid #e2e4e9;
    border-radius: 0.9375rem;
    padding: 0.4375rem;
  }

  .functions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 1rem;
    padding-inline: 1rem;

    .function-item {
    }
  }
}

.gender-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .gender-item {
    display: flex;
    align-items: center;
    gap: 1.7rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.625rem;
    padding: 0.8rem;
    position: relative;

    .gender-img {
      width: 3.1875rem;
      height: 3.1875rem;
      border-radius: 50%;
    }

    .goal-img {
      width: 2.1875rem;
      height: 2.1875rem;
    }

    .form-group-c {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}

.body-details-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .body-details-item {
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.625rem;
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.date-picker {
  input {
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    font-size: 1rem;
    color: #2c2c2c;
    width: 10.1875rem;

    &.just-input {
      padding: 0.5rem 0.8rem;
    }

    &:disabled {
      background: #e2e4e9;
      color: #aeafb2;
    }
  }
}

.input-incr-decr {
  display: flex;
  align-items: center;
  gap: 0.625rem;

  button {
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    padding: 0.5rem 0.9375rem;
    display: flex;
    align-items: center;
    width: 3rem;
    height: 2.4375rem;
    cursor: pointer;
  }

  input {
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    color: #2c2c2c;
    font-size: 1rem;
    line-height: 1.1875rem;
    padding: 0.5rem 0.9375rem;
    max-width: 7.125rem;
  }
}

.body-details-status-item {
  background: #f2fffa;
  box-shadow: 0px 1px 2px #1018280d;
  border: 2px solid #2ecf96cc;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;

  .info-img {
    background: #2ecf9633;
    padding: 0.3125rem;
    border-radius: 50%;
    margin-top: 0.125rem;
  }
}

.daily-routine-items {
  .daily-routine-item {
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 10px;
    padding: 1.25rem 1.5rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .daily-routine-item-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .accordian-toggle {
      background: #ffffff;
      box-shadow: 0px 1px 2px #1018280d;
      border: 1px solid #d0d5dd;
      border-radius: 7px;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        path {
          fill: #a5b5af;
        }
      }

      &.active {
        background: #2ecf96;
        box-shadow: 0px 1px 2px #1018280d;
        border: 2px solid #ededed;

        svg {
          transform: rotate(180deg);
          path {
            fill: #ffffff;
          }
        }
      }
    }

    .daily-routine-item-body {
    }
  }
}

.routine-time-item {
  margin-bottom: 0.8rem;
  .time-item {
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    width: 7.125rem;
    padding: 0.5rem 1rem;
  }
}

.form-group {
  input {
    margin-top: 0.6875rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 10px;
    padding: 0.8rem 1rem;
    font-size: 1rem;

    &::placeholder {
      color: #2c2c2c;
      opacity: 0.3;
    }

    &:focus {
      outline: transparent;
    }
  }

  .phone-input {
    margin-top: 0.6875rem;
    input {
      padding: 0.8rem 1rem;
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 10px;
    }
  }
}

.activity-level-content,
.personal-data-content {
  max-width: 45.75rem;
}

.activity-level-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  .activity-level-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.625rem;
    padding: 1.25rem;
    position: relative;

    // .overlay-item {
    //   background: #d0d5dd;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }

    &.disabled {
    }

    .react-select__control {
      box-shadow: 0px 1px 2px #e4e5e73d;
      border: 2px solid #e2e4e9;
      border-radius: 0.5rem;
    }

    .form-group-c {
      position: absolute;
      top: 0.8125rem;
      right: 0.6875rem;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.total-activities-time {
  .total-time {
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 8px;
    padding: 0.625rem 1rem;
  }
}

.weekly-sessions-item {
  background: #ffffff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding: 1.5rem;
}

.eating-habits-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .eating-habits-item {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.625rem;
    padding: 0.8rem;
    position: relative;

    .img {
      width: 3.1875rem;
      height: 3.1875rem;
      box-shadow: 0px 1px 2px #1018280d;
      border: 2px solid #efefef;
      border-radius: 10px;
      padding: 3px;
    }

    .form-group-c {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}

.personal-data-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.checkboxes-container {
  margin-top: 2rem;
}

.nutrition-creation-video-box {
  width: 100%;
  max-width: 54.875rem;
  padding: 2rem;
  margin-inline: auto;

  @media screen and (min-width: 1400px) {
    max-width: 68.875rem;
  }
}

.nutrition-type-update-content {
  max-width: 43rem;

  .nutrition-type {
    .nutrition-type-item {
      width: fit-content;

      img {
        background: #ffffff;
        box-shadow: 0px 1px 2px #1018280d;
        border: 1px solid #efefef;
        border-radius: 10px;
        padding: 0.5rem 0.7rem;
      }
    }
  }
}

.nutrition-advantages {
  .nutrition-advantages-item {
    background: #ffffff;
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 10px;
    padding: 1rem 0.625rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .index {
      color: #fff;
      background: #2ecf96;
      box-shadow: 0px 1px 2px #1018280d;
      border: 2px solid #ededed;
      border-radius: 17px;
      width: 1.75rem;
      min-width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.nutrition-category {
  background: #ffffff;
  box-shadow: 0px 2px 4px #02332a14;
  border: 2px solid #e2e4e9;
  padding: 0.5rem 1rem;
  border-radius: 1.875rem;
  cursor: pointer;

  span {
    color: #a5b5af;
  }

  &.active {
    background: #2ecf96;
    box-shadow: 0px 2px 4px #02332a14;
    border: 1px solid #44554c40;

    span {
      color: #ffffff;
    }
  }
}

.priority-item {
  background: #ffffff;
  box-shadow: 0px 2px 4px #02332a14;
  border: 2px solid #e2e4e9;
  border-radius: 20px;
  padding: 1rem;

  &.active {
    border-color: #2ecf96;
  }

  img {
    width: 3.2rem;
    height: 3.2rem;
  }
}

.tips-card,
.health-improvement-card,
.premium-caoching-card,
.subscription-details-card {
  background: #ffffff;
  box-shadow: 0px 13px 20px #d6d6d629;
  border: 2px solid #e2e4e9;
  border-radius: 15px;
  padding: 1.5rem;
}

.health-score-tips {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  .tips-card {
    // max-width: 21.875rem;
    // width: calc(100% - 2rem);
  }
}

.premium-caoching-card {
  .img {
    width: 100%;
    max-width: 9.375rem;
  }
}

.health-score-premium-coachings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;

  .line-one {
    display: contents; /* Ensures nested grid items are direct children of the grid container */
  }

  .line-one > div:nth-child(6) {
    grid-column: span 1;
  }

  .line-one > div:nth-child(7) {
    grid-column: span 2;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.supported-brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  .brand-item {
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #efefef;
    border-radius: 10px;
    height: 5rem;

    img {
      filter: grayscale(1);
    }
  }
}

.subscription-details-card {
  padding: 2rem;
}

.input-w-bg {
  background: #f6f8fa;
  box-shadow: 0px 1px 2px #e4e5e73d;
  border: 2px solid #e2e4e9;
  border-radius: 10px;
  padding: 0.625rem 0.9375rem;
  color: #142c1f;
  -webkit-text-fill-color: #142c1f;

  &::placeholder {
    color: #2c2c2c;
    opacity: 0.3;
  }
}

.macro-nutrients-charts-box {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  .chart-box {
    width: fit-content;
    margin: 0 auto;
  }
}
