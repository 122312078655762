@import url(./slick/slick.css);
@import url(./slick/slick-theme.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

h1,
h2,
h3,
h4,
h5,
h6,
strong,
table th {
  font-weight: 500 !important;
}

body {
  * {
    font-family: "Rubik", sans-serif !important;
    font-optical-sizing: auto;
    font-style: normal;
  }
}

.form-group {
  display: flex;
  flex-direction: column;

  label {
    color: #142c1f;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: left;
  }

  .input-note {
    color: #525866;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    font-weight: 300;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
}

.slick-dots li {
  button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: aliceblue;

    &:before {
      content: "" !important;
    }
  }

  &.slick-active {
    button {
      background-color: #2ecf96;
    }
  }
}

.mx-auto {
  margin-inline: auto !important;
}

.my-5 {
  margin-block: 4rem !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.btn-close {
  background-color: #e8e9e8;
  border-radius: 4px;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  width: fit-content;
  outline: none;
  border: none;
}

.btn-outline {
  background: #ffffff;
  box-shadow: 0px 1px 2px #5258660f;
  border: 1px solid #e2e4e9;
  border-radius: 10px;
  padding: 12px 20px;
  text-align: center;
  color: #141414;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  &.sm {
    padding: 10px 20px;
  }

  &.w-full {
    width: 100%;
  }

  &:not(:disabled):hover {
    box-shadow: 0px 1px 2px #5258664f;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.btn-dark {
  background: linear-gradient(180deg, #47584f 0%, #596860 100%);
  // background: linear-gradient(180deg, #ffffff1f 0%, #ffffff00 100%);

  border-radius: 10px;
  padding: 10px 27px;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 300;
  font-size: 0.9rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 4px 4px 0px #2ecf96;
    // background: linear-gradient(180deg, #2ecf96 0%, #2ecf96 100%);
  }
}

.btn-copy {
  background: #ffffff;
  box-shadow: 0px 1px 2px #5258660f;
  border: 1px solid #e2e4e9;
  border-radius: 0.625rem;
  padding: 0.7rem 0.9rem;
  color: #141414;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.online-status {
  background: #2ecf961a;
  border-radius: 1.125rem;
  color: #2ecf96;
  font-weight: 300;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;

  .dot {
    background: #2ecf96;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
  }
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.bg-white {
  background-color: #fff;
}

.copy-icon {
  box-shadow: 0px 1px 2px #5258660f;
  border: 1px solid #e2e4e9;
  border-radius: 0.25rem;
  padding: 0.1875rem;
  background-color: #fff;
  cursor: pointer;
}

.tag-label {
  background: #2ecf96;
  border-radius: 2.625rem;
  letter-spacing: 0.0175rem;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0.25rem 0.75rem;
  width: fit-content;
  height: auto;

  &.little-up {
    position: relative;
    top: -0.4125rem;
  }
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.relative {
  position: relative !important;
}

.z-1 {
  z-index: 1 !important;
}

.h-auto {
  height: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
.w-100 {
  width: 100% !important;
}
.w-60 {
  width: 61% !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.my-0 {
  margin-block: 0 !important;
}

.my-2-rem {
  margin-block: 2rem !important;
}

.my-1-5-rem {
  margin-block: 1.5rem !important;
}

.my-1-rem {
  margin-block: 1rem !important;
}

.line-height-1-5 {
  line-height: 1.5rem !important;
}

.line-height-1-25 {
  line-height: 1.25rem !important;
}

.divider {
  border-bottom: 1px solid #e2e4e9;
}
.lead-divider {
   border: 1px solid #E2E4E9;
   margin: 15px 30px 0px 30px; 
   height: 20px;

}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-1-rem {
  margin-bottom: 1rem !important;
}
.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}
.align-items-center {
  align-items: center !important;
}

.align-items-top {
  align-items: flex-start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}

.status-badge {
  border-radius: 5px;

  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  width: fit-content;

  &.fixed {
    width: 5.75rem;
  }

  &.orange {
    color: #d87845;
    background: #faefe7;
  }

  &.orange-yellow {
    color: #f39b29;
    background: #f39b291a;
  }

  &.green {
    color: #2ecf96;
    background: #e7faf3;
  }

  &.red {
    color: #e26868;
    background: #e268681a;
  }

  &.grey {
    color: #44554c;
    background: #f6f8fa;
  }
}

.grid-3-columns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.two-inputs-grid {
  display: grid;
  grid-template-columns: 9.375rem 1fr;
  gap: 1rem;
}

.create-form {
  &.partner {
    display: grid;
    grid-template-columns: 1fr 22.5rem;
  }

  .partner-form,
  .partner-upload {
    padding: 1.5rem;
  }

  .partner-form {
    border-right: 1px solid #e2e4e9;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }
}

.phone-input {
  input {
    background-color: white;
    color: #2c2c2c;
    -webkit-text-fill-color: #2c2c2c;
    font-size: 0.875rem;
    border: rgba(165, 181, 175, 0.15) 2px solid;
    width: 100%;
    padding: 9px 15px;
    border-radius: 5px;
    outline: none;
    margin: 0 auto;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputCountryIcon {
    border: 0;
    box-shadow: none;
    background-color: white;
  }

  .PhoneInputCountryIconImg {
    width: 3rem;
    top: 0;
    left: 0;
  }
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 0.6rem;

  .file {
    display: flex;
    align-items: center;
    gap: 0.3rem;

    img {
      width: 2.3rem;
    }

    p {
      color: #44554c;
      font-weight: 300;
      margin: 0;
    }

    small {
      color: #a5b5af;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
}

.upload-item {
  h6 {
    color: #142c1f;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 400;
    margin: 0;
  }

  .desc {
    color: #142c1f;
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-weight: 300;
    margin: 0;
  }

  img {
    margin: 4rem auto;
    display: block;
  }

  &.upload-logo {
    img {
      background: #ffffff;
      box-shadow: 0px 1px 2px #5258660f;
      border: 1px solid #e2e4e9;
      border-radius: 0.375rem;
      padding: 0.5rem;
    }
  }

  .upload-box {
    margin-top: 0.7rem;
    background: #ffffff;
    box-shadow: 0px 1px 2px #e4e5e73d;
    border: 2px solid #e2e4e9;
    border-radius: 0.9375rem;
    padding: 1.5rem;

    &:hover {
      background: #f9f9f9;
      cursor: pointer;
    }

    .upload-text {
      p {
        color: #818d88;
        font-size: 0.75rem;
        font-weight: 300;

        span {
          color: #2ecf96;
          font-weight: 400;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.congratulations-modal-body-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  img {
    width: 14.0625rem;
    display: block;
    margin: auto;
  }
}

.modal-body,
.congratulations-modal-body-inner {
  h6 {
    color: #142c1f;
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 500;
    margin: 0;
    text-align: center;
  }

  p {
    color: #142c1f;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 300;
    margin: 0;
    text-align: center;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagination {
  flex-direction: row !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0.5rem;

  .page-item {
    a {
      text-decoration: none;

      width: 1.875rem;
      height: 1.875rem;
      border-radius: 5px;
      border: 1px solid #dddddd;
      background: white;
      color: #44554c;
      cursor: pointer;
      line-height: 25px;
      font-size: 1rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    &:not(.disable).active,
    &:not(.disable):hover {
      a {
        border: none;
        background: #44554c;
        color: white;
      }
    }

    &.disabled {
      pointer-events: none;
      a {
        background: #f5f5f5;
        color: #cecccc;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}

.gap-3 {
  gap: 1rem;
}
.gap-5 {
  gap: 5rem;
}
.loader {
  width: 40px;
  height: 20px;
  --c: no-repeat radial-gradient(farthest-side, #000 93%, #0000);
  background: var(--c) 0 0, var(--c) 50% 0;
  background-size: 8px 8px;
  position: relative;
  clip-path: inset(-200% -100% 0 0);
  animation: l6-0 1.5s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 12px;
  background: #000;
  left: -16px;
  top: 0;
  animation: l6-1 1.5s linear infinite,
    l6-2 0.5s cubic-bezier(0, 200, 0.8, 200) infinite;
}
.loader:after {
  content: "";
  position: absolute;
  inset: 0 0 auto auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #000;
  animation: l6-3 1.5s linear infinite;
}
@keyframes l6-0 {
  0%,
  30% {
    background-position: 0 0, 50% 0;
  }
  33% {
    background-position: 0 100%, 50% 0;
  }
  41%,
  63% {
    background-position: 0 0, 50% 0;
  }
  66% {
    background-position: 0 0, 50% 100%;
  }
  74%,
  100% {
    background-position: 0 0, 50% 0;
  }
}
@keyframes l6-1 {
  90% {
    transform: translateY(0);
  }
  95% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(15px);
    left: calc(100% - 8px);
  }
}
@keyframes l6-2 {
  100% {
    top: -0.1px;
  }
}
@keyframes l6-3 {
  0%,
  80%,
  100% {
    transform: translate(0);
  }
  90% {
    transform: translate(26px);
  }
}

.loader-small {
  width: 1.25rem;
  padding: 0.1875rem;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #25b09b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.star-ratings {
  .widget-svg {
    &.hovered {
      fill: #ffc400;
    }
  }
}

.input-info {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  span {
    font-size: 0.8rem;
  }
}

.slider-content {
  padding: 10px 20px;

  h6 {
    font-weight: 200 !important;
    font-weight: 500 !important;
  }

  p {
    color: #a5b5af;
  }
}

.info-modal-slider {
  .arrows-text {
    font-weight: 400;
  }
  img {
    object-fit: cover;
    width: 100%;
  }

  .slide-item {
    background-color: #2da591;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
  }
  .slick-prev,
  .slick-next {
    top: -3.125rem;
  }

  .slick-prev {
    left: 30%;
  }

  .slick-next {
    right: 30%;
  }
}

.react-daterange-picker {
  &.styled {
    .react-daterange-picker__wrapper {
      box-shadow: 0px 1px 2px #1018280d;
      border: 1px solid #d0d5dd;
      border-radius: 0.625rem;
      padding: 0.65rem 0.75rem;

      input,
      .react-daterange-picker__range-divider,
      .react-daterange-picker__clear-button,
      .react-date-picker__inputGroup__divider {
        color: #44554c;
        font-size: 0.875rem;
        font-weight: 400;
      }

      .react-daterange-picker__range-divider {
        margin-right: 0.5rem;
      }

      .react-daterange-picker__button:enabled:hover
        .react-daterange-picker__button__icon,
      .react-daterange-picker__button:enabled:focus
        .react-daterange-picker__button__icon {
        stroke: #2ecf96;
      }
    }
  }

  .react-calendar {
    box-shadow: 0px 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 0.8125rem;

    .react-calendar__month-view__weekdays__weekday {
      abbr {
        font-weight: 400;
        text-decoration: none !important;
      }
    }

    .react-calendar__tile--hasActive,
    .react-calendar__tile--active {
      background: #2ecf96;
      color: #fff;
    }

    .react-calendar__tile--now,
    .react-calendar__tile--hasActive,
    .react-calendar__tile--active {
      border-radius: 0.5rem;
    }
  }
}

.form-group-c {
  display: block;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
  }
}

.form-group-c label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #fff;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 5px;
  padding: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group-c input:checked + label:before {
  background-color: #2ecf96;
}

.form-group-c {
  &.disabled {
    label:before {
      background-color: #f0f0f0;
    }

    input:checked + label:before {
      background-color: #898989;
    }
  }
}

.form-group-c input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2.2px;
  left: 10px;
  width: 4.5px;
  height: 13px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group-c {
  &.small {
    label:before {
      padding: 6px;
    }

    input:checked + label:after {
      top: 6px;
      left: 5.6px;
      width: 1.5px;
      height: 6px;
      border-width: 0 1.5px 1.5px 0;
    }
  }

  &.medium {
    label:before {
      padding: 10px;
    }

    input:checked + label:after {
      top: 4px;
      left: 8.5px;
      width: 3px;
      height: 9px;
      border-width: 0 2px 2px 0;
    }
  }
}
.month-year-dropdown {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  opacity: 1;
}

.alert {
  position: relative;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.file-item {
  gap: 0.5rem;

  a {
    color: #44554c;
    text-decoration: underline;
    font-weight: 400;
  }
}

.starter-content {
  height: calc(100vh - 7.4rem);
}

.gender-content,
.body-details-content,
.daily-routine-content,
.activity-level-content,
.diet-categories-content,
.eating-habits-content,
.personal-data-content,
.foodiary-benefits-content,
.nutrition-type-update-content,
.nutrition-recipies-content,
.nutrition-calories-content,
.nutrition-macro-content,
.health-score-investigation-content,
.health-score-proirities-content,
.health-score-all-anamnese-content,
.health-score-tips-tricks-content,
.health-score-improvements-content,
.health-score-premium-coachings-content,
.user-subscriptions-details-content,
.health-score-user-subscriptions-content,
.foodiary-family-joined-content,
.anamnese-evaluation-content {
  max-width: 33rem;
  width: 100%;
  margin-inline: auto;
}

.nutrition-macro-content,
.nutrition-calories-content,
.health-score-proirities-content,
.health-score-tips-tricks-content,
.user-subscriptions-details-content {
  max-width: 45rem;
}

.health-score-all-anamnese-content,
.health-score-premium-coachings-content {
  max-width: 56rem;
}

.foodiary-benefits-content,
.health-score-improvements-content,
.health-score-user-subscriptions-content {
  max-width: 60rem;
}

.anamnese-evaluation-content {
  max-width: 68.75rem;
}

.creation-video-content {
  height: calc(100vh - 7.4rem);
}

.hand {
  position: absolute;
  bottom: -27px;
  right: -21px;
  width: 130px;
  height: 200px;
  z-index: 0;
}

.drag-drop-context {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.categories-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.categories-buttons {
  display: flex;
  gap: 0.5rem;

  .item {
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 0.4375rem 1rem;
    cursor: pointer;
    &:hover,
    &.active
    {
      border-color: #d0d5dd;
      box-shadow: 0px 1px 2px #1018280d;
    }
  }
}


// ------------------------------

.drag-drop-context {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 26px;
  overflow: auto;

  .taskWrap {
    max-height: 730px;
    overflow-y: scroll;
    min-width:17.5rem;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.All {
    .taskWrap {
      display: block;
      min-width: 17.5rem;
    }
  }

  &.Erhalten {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.Kontaktiert {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(4),
    .taskWrap:nth-child(5) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.Erstgesprach {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(5) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.Verloren {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.KeinInteresse {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.NoShow {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
      min-width: 17.5rem;

    }
  }

  &.InBetreuung {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
    }
  }

  &.Storniert {
    .taskWrap:nth-child(1),
    .taskWrap:nth-child(2),
    .taskWrap:nth-child(3),
    .taskWrap:nth-child(4) {
      display: none;
    }
  }
}
.padding-large-screen{
  @media (min-width: 1600px) {
    padding: 0 4rem;
    
  }
}

.categories-buttons {
  display: flex;
  gap: 0.5rem;

  .item {
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 0.4375rem 1rem;
    cursor: pointer;

    &:hover,
    &.active {
      border-color: #d0d5dd;
      box-shadow: 0px 1px 2px #1018280d;
    }
  }
}

.taskWrap {
  background-color: #f6f8fa;
  box-shadow: 0px 1px 2px #1018280d;
  border: 1px solid #e2e4e9;
  border-radius: 15px;
}

.taskColorOne {
  background-color: #9a9a9a;
}

.taskColorTwo {
  background-color: #ffc350;
}

.taskColorThree {
  background-color: #65c9fb;
}

.taskColorFour {
  background-color: #52AA9C;
}
.taskColorFive {
  background-color: #ee2b2a
}
.taskColorSix {
  background-color: #ee2b2a;
}
.taskColorSeven {
  background-color:#52AA9C;
}
.taskColorEight {
  background-color: #ee2b2a
}

.taskWrapHead {
  display: flex;
  justify-content: space-between;
  padding: 15px;

  .taskWrapHeadLeft {
    display: flex;
    gap: 10px;
    align-items: center;

    h3 {
      margin: 0px;
      color: #142c1f;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      width: 15px;
      height: 15px;

      border-radius: 50%;
    }
  }

  .taskWrapHeadRight {
    display: flex;
    text-align: center;
    span {
      padding: 0.1125rem 1rem;
      box-shadow: 0px 1px 2px #1018280d;
      border: 1px solid #d0d5dd;
      border-radius: 12px;
      color: #818d88;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
}

.taskItemsUpper {
  padding: 15px;
}

.taskItemsWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  max-height: 669px;
  overflow-y: auto;
}

.taskItems {
  background-color: #ffffff;
  border: 1px solid #e2e4e9;
  border-radius: 10px;

  .taskItemsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }

    span {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      overflow: hidden;

      img {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  .taskItemsMid {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .taskItemsMidRow {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        color: #818d88;
        font-weight: 400;
      }
    }
  }

  .taskItemsLast {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e2e4e9;
    padding: 10px;

    .taskItemsLastName {
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    }

    button {
      background-color: #ffffff;
      box-shadow: 0px 1px 2px #1018280d;
      border: 1px solid #d0d5dd;
      border-radius: 6px;
      font-size: 12px;
      font-weight: 400;
      color: #142c1f;
      cursor: pointer;
      padding: 4px 14px;
    }
  }
}

.lead-details {
  .general-info-box,
  .personal-data,
  .lead-details,
  .lead-actions {
    box-shadow: 0 1px 2px #1018280d;
    border: 1px solid #d0d5dd;
    border-radius: 13px;
    padding: 1.5rem;
  }

  .dates-row,
  .personal-data-info,
  .lead-details-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .lead-first-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    .sub-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    }
  }
  .lead-second-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    gap: 0rem;
    margin-top: 2rem;
  }

  .lead-actions-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    p {
      max-width: 580px;
    }
  }
}
 .vertical-line {
    border: 1px solid #E2E4E9;;
    height: 1.375rem;
    margin-top: 1rem;
    position: absolute;
    position: absolute;
    top: 50%;
    left: 19vw;
    }

.chip-outline {
  background-color: transparent;
  color: #a5b5af;
  border: 1px solid #e2e4e9;
  font-size: 0.8rem;
  border-radius: 14px;
  padding: 6px 12px;
  cursor: pointer;

  &.active {
    background-color: #2ecf96;
    color: white;
  }
}

.container-70rem {
  max-width: 70rem;
  margin-inline: auto;
}
.pl-4{
  padding-left: 20% !important;

}
.back-arrow-icon-div {
    display: flex;
    gap: 5px;
    align-items: center;
}
:root {
  --color1: #2ecf96;
  --color2: #ffc400;
  --color3: #c74343;
  --color4: blue;
  --color5: orange;
}

.legends {
  list-style: none;
  /* padding: 1rem 1.5rem; */
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  max-width: 230px;

  & > div {
    // margin-right: 30px;
    display: flex;

    &:not(:last-child) {
      margin-bottom: 0.5em;
    }

    & > span {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      &:nth-child(2) {
        margin-inline: 0.6rem;
      }
    }

    &:before {
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
      vertical-align: middle;
      border-radius: 50%;
    }
    &:nth-child(5n + 1)::before {
      background: var(--color1);
    }
    &:nth-child(5n + 2)::before {
      background: var(--color2);
    }
    &:nth-child(5n + 3)::before {
      background: var(--color3);
    }
    &:nth-child(5n + 4)::before {
      background: var(--color4);
    }
    &:nth-child(5n + 5)::before {
      background: var(--color5);
    }
  }
}

.apexcharts-toolbar {
  display: none !important;
}

.apex-clients-calories-chart {
  padding: 1.5rem;
}


.swal2-styled.swal2-confirm {
  background-color: #2ecf96 !important;
}

