// .client-details-donut-container div {
//   position: relative;

//   :nth-child(1) {
//     right: -300px;
//   }

//   :nth-child(2) {
//     > div {
//       position: absolute;
//       left: 150px;
//     }

//     ::before {
//       border-radius: 50%;
//     }

//     :nth-child(1) {
//       top: -100px;
//     }

//     :nth-child(2) {
//       top: -150px;
//     }

//     :nth-child(3) {
//       top: -200px;
//     }
//   }
// }

.btn-share {
  all: unset;
  color: #a5b5af;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  img {
    width: 16px;
  }
}

.react-select__control {
  &.react-select__control--is-focused {
    border-color: #dce1e0 !important;
    box-shadow: 0 0 1px #dce1e0 !important;
  }
  .react-select__single-value,
  .react-select__placeholder,
  .react-select__input {
    font-size: .875rem !important;
    font-weight: 300;
  }
  .react-select__indicator-separator {
    display: none;
  }
}

.react-select__menu {
  font-size: .875rem !important;
  font-weight: 300;
}
