:root {
  --PhoneInputCountryFlag-height: 2em;
}
.text-center {
  text-align: center;
}

.my-10 {
  margin-block: 10rem;
}
.topbar-toolbar {
  padding-inline: 1.25rem !important;
}

.dashboard-navbar {
  background: #fafafa;
  border-bottom: 2px solid #eeeeee;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 2;
  width: calc(100% - 7.625rem);
}

.dashboard-navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem;
}

.new-dashboard-layout-container {
  display: flex;
  justify-content: right;
  align-items: stretch;
  height: 100vh;
  width: auto;
  gap: 1.25rem;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 4.875rem; */
  width: 7.625rem;
  height: 100%;
  /* background-color: #111614; */
  background: linear-gradient(180deg, #6cbfb2 0%, #1b9c86 100%);
  color: #f1f1f1;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  overflow-y: hidden;
  z-index: 9;
}

/* .sidebar:hover, */
.sidebar.sidebar-open {
  width: 20rem;
}

.main {
  width: calc(100% - 7.625rem);
  min-height: 100vh;
  color: var(--text-color);
  transition: all 0.2s;
  box-sizing: border-box;
  background: #fafafa;
}
.sidebar.sidebar-open + .main {
  width: calc(100% - 20rem);
}

.sidebar.sidebar-open + .main .dashboard-navbar {
  width: calc(100% - 20rem);
}

.sidebar.sidebar-close + .main .dashboard-navbar {
  width: calc(100% - 7.625rem);
}

.page-content-wrapper {
  margin-top: 4.625rem;
  height: 100%;
}

/* side bar nav */
.sideNav .MuiPaper-root {
  background-color: #111614;
  color: #f1f1f1;
}
.sideNav .MuiPaper-root::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sideNav .MuiPaper-root::-webkit-scrollbar-track {
  background: #111614;
}

/* Handle */
.sideNav .MuiPaper-root::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */
.sideNav .MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sideNav .MuiIconButton-root {
  width: 28px;
  height: 44px;
  opacity: 1;
  border-radius: 10px 0px 0px 10px;
  color: #f1f1f1;
  background-color: #343937;
}
.sideNav .MuiIconButton-root:hover {
  background-color: #343937;
}
.sideNav .makeStyles-toolbar-9 {
  padding: 30px 0 0 40px;
  justify-content: space-between;
  width: 100%;
}
.makeStyles-drawerClose-8.sideNav .newDBLogo {
  display: none;
}
.makeStyles-drawerClose-8.sideNav .MuiIconButton-root {
  display: none;
}

.sideNav ul li .MuiSvgIcon-root {
  /* margin-right: 1rem; */
}
.sideNav ul {
  padding: 0;
  flex-direction: column;
  display: flex;
  margin-top: 0 !important;
}
.sideNav ul li:hover {
  /* color: #2ecf96; */
}
.sideNav ul li {
  /* font-weight: 500; */
  padding: 0;
  font-size: 0.875rem;
  /* line-height: 1.5rem; */
  width: auto;
  display: flex;
  justify-content: center;
  /* margin-left: 40px; */
}
.makeStyles-drawerClose-8.sideNav ul li span {
  display: none;
}
.makeStyles-drawerClose-8.sideNav ul li .MuiSvgIcon-root {
  margin-right: 0px;
}
.makeStyles-drawerClose-8.sideNav ul li {
  margin-left: 20px;
}
.makeStyles-drawerClose-8.sideNav .qlinks {
  display: none;
}

.sideNavLink .MuiAccordionSummary-root {
  margin: 0 0 0 2.5rem;
  min-height: 1.5rem !important;
  padding: 0;
  height: 1.5rem !important;
}
.sideNavLink .sidebar-list-item a {
  color: white !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  border-radius: 10px;
  gap: 0.5rem;
  width: 100%;
}
.sideNavLink .sidebar-list-item a.active-link,
.sideNavLink .sidebar-list-item:hover a {
  background: #f5f5f54a;
}

/* .sidebar-list-item .icon-active {
  display: none;
}

.sidebar-list-item .active-link .icon {
  display: none;
}

.sidebar-list-item .active-link .icon-active {
  display: block;
} */

.sideNavLink .sidebar-list-item:hover {
  cursor: pointer;
}
.sideNavLink .sidebar-list-item:hover a,
.sideNavLink .sidebar-list-item a.active-link,
.sideNavLink .sidebar-list-item:hover a span {
  /* color: #2ecf96 !important; */
}

.sideNavLink .sidebar-list-item a:after {
  content: "";
  width: 1rem;
  height: 1rem;
  position: absolute;
  right: 1.5rem;
}
.sideNavLink .sidebar-list-item:hover a:after,
.sideNavLink .sidebar-list-item a.active-link:after {
  background-image: url("../assets/images/active-arrow.png");
}

.sidebar-close .sideNavLink .sidebar-list-item:hover a:after,
.sidebar-close .sideNavLink .sidebar-list-item a.active-link:after {
  background-image: none;
}

.makeStyles-drawerClose-8.sideNav .sideNavLink .MuiAccordionSummary-root {
  margin: 0 0 2.5rem 1.25rem;
}
.sideNavLink .MuiAccordionSummary-root .MuiIconButton-root {
  width: auto;
  height: auto;
  background-color: transparent;
  opacity: 1;
  padding: 0;
  margin-left: 0.625rem;
  margin-right: 0;
}

.sideNavLink .MuiAccordionSummary-root .MuiTypography-body1 {
  display: flex;
  font-weight: 500;
  padding: 0 0 0 0px;
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.sideNavLink .MuiAccordionSummary-root .MuiTypography-body1 span {
  font-weight: 500;
  padding: 0 0 0 0px;
  font-size: 1.125rem;
  line-height: 1.375rem;
}
.sideNavLink .MuiAccordionSummary-root .MuiTypography-body1 .MuiSvgIcon-root {
  margin-right: 1.875rem;
}
.makeStyles-drawerClose-8.sideNav
  .sideNavLink
  .MuiAccordionSummary-root
  .MuiTypography-body1
  span {
  display: none;
}
.makeStyles-drawerClose-8.sideNav
  .sideNavLink
  .MuiAccordionSummary-root
  .MuiTypography-body1
  .MuiSvgIcon-root {
  margin-right: 0;
}
.sideNavLink .MuiAccordionSummary-content {
  margin: 0;
}
.sideNavLink .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}
.sideNavLink .MuiCollapse-entered {
  padding-left: 2.5rem;
}
.sideNavLink .MuiCollapse-entered .MuiLink-root {
  color: #f1f1f1;
}
.secondList {
  margin-top: 2.5rem !important;
}
.makeStyles-drawerClose-8.sideNav .secondList {
  margin-top: 0 !important;
}

.sideNav .MuiCollapse-wrapperInner {
  padding: 1.25rem 0 0 3.125rem;
}

.makeStyles-content-10 {
  padding-left: 0 !important;
  height: 100vh;
  padding-bottom: 0 !important;
}

.sideNav .MuiAccordionSummary-expandIcon {
  transform: rotate(270deg) !important;
}
.sideNav .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(360deg) !important;
}

.MuiAppBar-colorPrimary {
  /* background-color: #F1F1F1 !important; */
  color: unset !important;
}
.navbar-dynamic-page-title {
  color: #142c1f;
  font-size: 1.625rem;
  line-height: 1.9375rem;
  /* font-family: "RubikMedium" !important; */
  margin-block: 0;
  /* font-weight: 400 !important; */
}

.navbar-actions {
  display: flex;
  align-items: center;
}

.navbar-actions button {
  margin-right: 20px;
}

.makeStyles-menuButton-4 {
  color: #111614 !important;
}

.ml-auto {
  margin-left: auto;
}

.sidebar-list-item img {
  width: 1.1rem;
  /* margin-right: 1rem; */
}

.custum-scroll::-webkit-scrollbar {
  width: 0.3125rem;
}

/* Track */
.custum-scroll::-webkit-scrollbar-track {
  background: #111614;
}

/* Handle */
.custum-scroll::-webkit-scrollbar-thumb {
  background: #2ecf96;
}

/* Handle on hover */
.table-styled::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.full-width {
  width: 100%;
}

.input-custom {
  border: 1px solid #dddddd !important;
  border-radius: 8px !important;
  width: 100% !important;
  /* height: 2.6875rem; */
}

.bg-transparent {
  background-color: transparent !important;
}
.textarea-box textarea {
  border: 1px solid #dddddd !important;
  border-radius: 8px;
  background: transparent;
}

.textarea-box.mb {
  margin-bottom: 1rem;
}

.copy-text {
  padding-right: 50px !important;
}
.info-label {
  justify-content: flex-start !important;
  align-items: center;
  display: flex;
}
.info-icon {
  position: relative !important;
  transform: unset !important;
  right: unset !important;
  margin-left: 0.4375rem;
}

.profile-box {
  margin: 2.5rem 0 0 0 !important;
  padding: 1.875rem 0 1.875rem 1.5625rem !important;
}
.id-text {
  text-align: center;
  color: #44554c;
}
.id-text div {
  justify-content: center;
  font-weight: 500;
}
.id-text input {
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  color: #44554c !important;
  padding: 0 !important;
  font-weight: 400;
  height: 1.6875rem !important;
  width: 6.0625rem !important;
}
.edit-color {
  color: #1c9d87;
}
.masonry-image img {
  width: 100%;
  display: block;
}
.button-hover-box {
  position: relative;
}
.button-hover-box .plus-btn {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.button-hover-box .plus-btn span {
  width: 0.6875rem;
  height: 0.6875rem;
  color: #a5b5af;
  font-size: 1.5625rem;
}

.button-hover-inner {
  display: none;
  position: absolute;
  top: 0;
  left: 40px;
  animation: slide-in 0.3s ease;
  width: 150px;
  padding-left: 0.625rem;
  /* display: inline-flex; */
}

.button-hover-inner a:last-child {
  margin-right: 0;
}

.full-image {
  position: absolute;
  bottom: 0;
}

.full-image .button-hover-box .plus-btn {
  background-color: #ffffff59;
}
.full-image .button-hover-box .plus-btn span {
  color: #fff;
}
.full-image .button-hover-inner a {
  background-color: #fff;
}

.full-image .button-hover-inner a.greenColor {
  background-color: #2ecf96 !important;
  border-color: transparent;
}

/* .sidebar .newDBLogo, */
/* .sidebar .sideNavLink .sidebar-list-item a span, */
.sidebar .qlinks
/* .sidebar ul li span  */ {
  /* display: none; */
}
/* .sidebar.sidebar-open .newDBLogo, */
.sidebar.sidebar-open .sideNavLink .sidebar-list-item a span,
.sidebar.sidebar-open .qlinks,
.sidebar.sidebar-open li span {
  display: block;
}

/* .sidebar:hover .newDBLogo,
.sidebar:hover .sideNavLink .sidebar-list-item a span,
.sidebar:hover .qlinks,
.sidebar:hover li span  */

/* .sideNav .sidebar:hover ul li, */
.sideNav .sidebar.sidebar-open ul li {
  /* padding-left: 2.5rem; */
}
.sideNav .sidebar ul li {
  padding: 0.2rem 0.5rem;
}
.sidebar .qlinks {
  box-sizing: border-box;
}

.sidebar .custum-scroll {
  width: 100%;
  overflow-y: auto;
}

.sidebar .icon-change {
  padding-left: 2.5rem;
}
.sidebar .icon-change .MuiIconButton-root {
  background-color: transparent;
}
.sidebar.sidebar-open .icon-change .MuiIconButton-root {
  background-color: #343937;
}
/* 27px */
.sideNav .sidebar-close ul li {
  /* padding-left: 1.6875rem !important; */
}

.sideNav .sidebar-close .icon-change {
  padding-left: 0;
}

.sidebar-close .sidebar-list-item a {
  flex-direction: column;
  text-align: center;
}

.sidebar .scroll-box {
  overflow: hidden;
  transition: all .3s ease-in-out;
}

.sidebar .scroll-box:hover {
  overflow-y: auto;
}

.sidebar .scroll-box::-webkit-scrollbar {
  width: 4px;
}
.sidebar .scroll-box::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10rem;
}

.sidebar .scroll-box::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.sidebar .scroll-box::-webkit-scrollbar-track-piece:end {
  background: transparent;
}

.social-media-menu {
  position: fixed;
  bottom: 6.1125rem;
  left: 7.5rem;
  width: fit-content;
  z-index: 98;
}

.social-media-menu.hidden {
  left: 200%;
}
.social-media-menu.show {
  left: 7.5rem;
}

.social-media-menu-items {
  background: #35A490;
  padding: 0.7rem;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  display: flex;
  gap: 0.5rem;
}

/* ------------------------ Button Hover  -------------------------- - */

.button-hover-box {
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* width: 100%; */
}

.button-hover-inner {
  display: none;
  position: absolute;
  top: 0;
  left: 2.0625rem;
  animation: slide-in 0.3s ease;
  width: fit-content;
  padding-left: 0.9375rem;
  /* display: inline-flex; */
}
.button-hover-box.hovered {
  width: 100%;
}
.button-hover-box.hovered .button-hover-inner {
  display: inline-flex;
  /* left: 50px; */
}

/* Style the links inside .button-hover-inner as needed */
.button-hover-inner a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  text-decoration: none;
  color: #333;
  margin-right: 0.375rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid #dddddd;
  box-sizing: border-box;
}
.button-hover-inner a:last-child {
  margin-right: 0;
}
.button-hover-inner a img {
  width: 1.5rem;
  height: auto !important;
}
@keyframes slide-in {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

.greenColor {
  background-color: #2ecf96;
  border: none !important;
}

.canva-image {
  width: 1.75rem !important;
}
.greenColor img {
  width: 1rem !important;
}
.close_icon {
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 45px;
  position: relative;
  cursor: pointer;
}
.close_icon::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 20px;
  border-radius: 8px;
  background: #fff;
  left: 22px;
  top: 12px;
  transform: rotate(45deg);
}
.close_icon::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 20px;
  border-radius: 8px;
  background: #fff;
  left: 22px;
  top: 12px;
  transform: rotate(-45deg);
}

.second-sidebar-wrapper {
  width: 280px;
  height: 100vh;
  position: fixed;
}

.second-sidebar-content {
  width: calc(100% - 280px);
  margin-left: 280px;
}
.custom_checkbox .eobKde input[type="checkbox"] {
  border-radius: 5px !important;
  width: 1.25rem;
  height: 1.25rem;
}
.custom_checkbox .eobKde input[type="checkbox"]:checked + span {
  text-decoration: none;
}

.promo-copy-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E2E4E9;
  width: 35px;
  height: 35px;
  border-radius: 7px;
  transition: all 100ms ease-in-out;

  &:hover {
    transform: scale(1.2)
  }

  img {
    width: 15px;
  }
}

.screen-size-warning {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #44554C;
  background-color: #E7FAF3;
  text-align: center;
  padding: 30px;
}

.screen-size-content {
  background-color: white;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}

.screen-size-content > * {
  margin-bottom: 10px;
}

.screen-size-logo img {
  width: 100px;
}
